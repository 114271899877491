//  零售-商品列表
import CustomStepper from "@/components/common/custom-stepper/index.vue";
export default {
  name: "retail-product-item",
  components: { CustomStepper },
  props: {
    productItem: {
      type: Object,
      default: () => {
        return {
          productId: "fzkl001",
          name: "肥宅快乐水",
          inventory: 999,
          quantity: 0,
          thumb:
            "http://7xr2vd.com1.z0.glb.clouddn.com/FnLNf7WPeVYhMTsbQMdtYirBNXct",
          formatName: "规格名称",
          convertCount: 40,
          showUnit: "箱",
          unit: "盒",
        };
      },
    },
    showInventory: {
      type: Boolean,
      default: true,
    },
    showStepper: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onQuantityChange(val) {
      this.$emit("update:quantity", val);
      //  这里实际上还没有修改到quantity
      this.$emit("quantityChange", this.productItem.productId);
    },

    // 点击sku
    onSku(){
      this.$emit('sku')
    }
  },
};
