const map = {
  "0": "待发货",
  "1": "已发货",
  "2": "已取消",
};

//  获取订单状态
export function getRetailState(state) {
  /* 0待发货，1已发货，2已取消 */
  return map[state];
}
