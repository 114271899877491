//  零售订单列表
//  非常简单的写，后端没有分页，所以每个tab的数据都保存在orderList下，也不缓存tab数据
import { mapState } from "vuex";
import AddressDetail from "@/components/address/address-detail/index.vue";
import RetailProductItem from "@/components/retail-order/retail-product-item/index.vue";
import { getRetailOrderDetail } from "@/service/retail-order/index.js";
import { getRetailState } from "@/constants/retail.js";
import { toExpress } from "@/utils/common.js";

export default {
  name: "retail-order-detail",
  components: {
    RetailProductItem,
    AddressDetail,
  },
  filters: {
    getRetailState(state) {
      return getRetailState(state);
    },
  },
  data() {
    return {
      order: {
        addressInfo: {},
      },
    };
  },
  methods: {
    onClickLink(item, e) {
      e.preventDefault();
    },
    toExpress(item, e) {
      toExpress(item, e);
    },
  },
  created() {
    getRetailOrderDetail({ code: this.$route.query.orderId }).then((res) => {
      if (res) {
        res.addressInfo = res.addressText ? JSON.parse(res.addressText) : {};
        res.addressInfo.fullAddress = res.addressInfo.address;
        res.addressInfo.name = res.addressInfo.userName;
        this.order = res;
      }
    });
  },
};
